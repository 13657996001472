<template>
  <div>
    <div v-if="showMissingPendencies()" class="pendencies">
      <div>
        Não foi possível gerar as pendências para este agendamento por conta da data de criação do mesmo que antecede essa funcionalidade.
        Caso queira, você pode fazer isso manualmente clicando no botão ao lado.
      </div>
      <b-button
        size="sm"
        variant="outline-success"
        @click="generatePendencies"
      >
        Gerar pendências
      </b-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    form: Object,
    validateForm: Function,
    getPayloadData: Function,
  },
  data() {
    return {
      missingPendencies: [],
    }
  },
  methods: {
    showMissingPendencies() {
      return this.form?.id && this.missingPendencies.length
    },
    generatePendencies() {
      if(!this.validateForm()) return;
      const data = this.getPayloadData();
      data.appointment_items.forEach(item => {
        if(this.missingPendencies.find(pendency => pendency.id === item.id))
          item.solved = false;
      })
      const isLoading = this.$loading.show()
      this.api.updateAppointment(this.form.id, data)
      .then(() => {
        this.verifyPendencies()
        this.$toast.success('Pendências geradas com sucesso')
      })
      .catch(err => this.$toast.error(err.message))
      .finally(() => { isLoading.hide() })
    },
    async verifyPendencies() {
      if (!this.form?.id) return
      const { data } = await this.api.getMissingPendencies(this.form.id)
      this.missingPendencies = data
      this.$forceUpdate()
    },
  },
  watch: {
    form() {
      this.verifyPendencies()
    }
  }
}
</script>

<style lang="scss" scoped>
.pendencies {
  width: 100%;
  margin-bottom: 20px;
  padding: 10px;
  font-size: 13px;
  color: #28a745;
  border: 1px dashed;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 30px;

  button {
    white-space: nowrap;
  }
}
</style>
